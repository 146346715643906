<template>
  <div class="col-lg-3 col-12 px-lg-0 order-last">
    <div class="row g-2 align-items-center">
      <div class="col-6 col-lg-12 mb-lg-2">
        <Button color="goldGreen" width="100" :btnText="addToCartText" icon="arrow" @buttonClicked="addToCart" :disabled="saveDisabled">
          <IconCart size="size16" />
        </Button>
      </div>
      <div class="col-6 col-lg-12">
        <Button color="red" width="100" :btnText="donateText" icon="arrow" @buttonClicked="donate" :disabled="saveDisabled">
          <IconArrowForward />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconCart: defineAsyncComponent(() => import('@/components/icons/IconCart.vue'))
  },
  name: 'The Buttons',
  props: ['isAdded', 'saveDisabled'],
  emits: ['addToCart', 'donate'],
  computed: {
    addToCartText () {
      let ret = 'Add to Cart'
      if (this.isAdded) {
        ret = 'Added'
      }
      return ret
    },
    donateText () {
      const ret = 'Make Donation'
      return ret
    }
  },
  methods: {
    addToCart () {
      this.$emit('addToCart')
    },
    donate () {
      this.$emit('donate')
    }
  }
}
</script>
